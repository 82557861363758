import {
  format as dateFnsFormat,
  fromUnixTime,
  getDay,
  isDate,
  parse,
  parseISO,
  subHours,
} from 'date-fns'
import { format as dateFnsTzFormat, toDate } from 'date-fns-tz'
import { isNumber } from 'lodash'

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const DAYS_OF_WEEK_SMALL = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
const DAYS_OF_WEEK_XSMALL = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S']
const DAYS_OF_WEEK_XXSMALL = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const MONTHS_OF_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const TIMES = [
  {
    label: '6:00 AM',
    value: '06:00',
  },
  {
    label: '7:00 AM',
    value: '07:00',
  },
  {
    label: '8:00 AM',
    value: '08:00',
  },
  {
    label: '9:00 AM',
    value: '09:00',
  },
  {
    label: '10:00 AM',
    value: '10:00',
  },
  {
    label: '11:00 AM',
    value: '11:00',
  },
  {
    label: '12:00 PM',
    value: '12:00',
  },
  {
    label: '1:00 PM',
    value: '13:00',
  },
  {
    label: '2:00 PM',
    value: '14:00',
  },
  {
    label: '3:00 PM',
    value: '15:00',
  },
  {
    label: '4:00 PM',
    value: '16:00',
  },
  {
    label: '5:00 PM',
    value: '17:00',
  },
  {
    label: '6:00 PM',
    value: '18:00',
  },
  {
    label: '7:00 PM',
    value: '19:00',
  },
  {
    label: '8:00 PM',
    value: '20:00',
  },
  {
    label: '9:00 PM',
    value: '21:00',
  },
  {
    label: '10:00 PM',
    value: '22:00',
  },
  {
    label: '11:00 PM',
    value: '23:00',
  },
  {
    label: '12:00 AM',
    value: '00:00',
  },
  {
    label: '1:00 AM',
    value: '01:00',
  },
  {
    label: '2:00 AM',
    value: '02:00',
  },
  {
    label: '3:00 AM',
    value: '03:00',
  },
  {
    label: '4:00 AM',
    value: '04:00',
  },
  {
    label: '5:00 AM',
    value: '05:00',
  },
]

const API_FORMAT = 'yyyy-MM-dd'
const TIMESTAMP_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`
const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

const format = (date: unknown, formatString = 'MMMM do, y') => {
  if (!date) return ''

  let value = date

  if (isNumber(value)) value = fromUnixTime(date as number)
  if (!isDate(value)) value = parseISO(date as string)
  if (!isDate(value)) value = new Date(date as Date | number | string)

  if (!isDate(value)) return date

  try {
    return dateFnsFormat(value, formatString)
  } catch {
    return date
  }
}

const api = (date: string) => format(date, API_FORMAT)

const display = (date: string) => format(date)

const displayWithTime = (date: string) =>
  `${format(date, 'eeee, MMMM do')} at ${format(date, 'h:mm a')}`

const displayWithDayOfWeek = (date: string) => `${format(date, 'eeee, MMMM do')}`

const displayShortWithTime = (date: string) => `${format(date, 'M/d')} ${format(date, 'h:mm a')}`

const displayDayWithTime = (date: string) => `${format(date, 'eeee')} at ${format(date, 'h:mm a')}`

const displayTimes = (date: string, shouldDisplayInLocalTz = false) => {
  const hourSuffix = ':00'
  const timeFormat = 'h:mma z'
  if (shouldDisplayInLocalTz) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const localTime = dateFnsTzFormat(toDate(date), timeFormat, { timeZone })
    return `${localTime.replace(hourSuffix, '')}`
  }
  const et = dateFnsTzFormat(toDate(date), timeFormat, { timeZone: 'America/New_York' })
  const pt = dateFnsTzFormat(toDate(subHours(new Date(date), 3)), timeFormat, {
    timeZone: TIMEZONE,
  })

  return `${et.replace(hourSuffix, '')} / ${pt.replace(hourSuffix, '')}`
}

const displayWithTimes = (date: string) => `${displayTimes(date)} on ${format(date)}`

const displayUTCInLocalWithTime = (date: string) => `${format(date)} at ${displayTimes(date, true)}`

const input = (date: string) => {
  const [dateWithoutTimestamp] = date.split('T')
  const parsedDate = parse(dateWithoutTimestamp, 'yyyy-MM-dd', new Date())
  return format(parsedDate, 'MM/dd/yyyy')
}

const monthWordAndDay = (date: string) => format(date, 'MMMM do')

const time = (date: string) => format(date, 'h:mm a')

const timestamp = (date: string) => format(date, TIMESTAMP_FORMAT)

const getDayOfWeek = (date) => DAYS_OF_WEEK[getDay(date)]

const getNextDayOfWeek = (dayOfWeek: string, date = new Date()) => {
  const dateCopy = new Date(date.getTime())
  const indexOfDayOfWeek = DAYS_OF_WEEK.indexOf(dayOfWeek)
  const nextDay = new Date(
    dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + indexOfDayOfWeek) % 7 || 7))
  )
  return nextDay
}

const getDateFromYYYY_MM_DD = (date: string) => parse(date, 'yyyy-MM-dd', new Date())

const getDateFromTimestamp = (date: string) => parse(date, TIMESTAMP_FORMAT, new Date())

const getYYYY_MM_DDFromTimestamp = (date: string) => date.slice(0, 10)

export default {
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_SMALL,
  DAYS_OF_WEEK_XSMALL,
  DAYS_OF_WEEK_XXSMALL,
  MONTHS_OF_YEAR,
  TIMES,
  TIMESTAMP_FORMAT,
  TIMEZONE,

  api,
  display,
  displayDayWithTime,
  displayShortWithTime,
  displayTimes,
  displayUTCInLocalWithTime,
  displayWithDayOfWeek,
  displayWithTime,
  displayWithTimes,
  format,
  getDateFromTimestamp,
  getDateFromYYYY_MM_DD,
  getDayOfWeek,
  getNextDayOfWeek,
  getYYYY_MM_DDFromTimestamp,
  input,
  monthWordAndDay,
  time,
  timestamp,
}
